<template>
  <div v-if="showed" class="document-app-container">
    <variaveis @insertVar="insertVar" ref="vars"/>
    <div @click="() => menuActive !== 'edit' && hide()" class="overlay"></div>
    <div class="documento-app" :class="{closed: closed}">
      <div v-if="documento && documento.id" class="float-options">
        <a :class="{active: m.id === menuActive}" v-for="m in menu.slice().reverse()" @click="changeMenu(m)">{{
            m.label
          }}</a>
        <!--        <a class="close-btn"><u-icon name="times" type="fa" /></a>-->
      </div>
      <div v-if="!loading" class="documento-container e-input-modern size1 ">
        <div>
          <div v-if="menuActive === 'edit'">
            <e-row mr>
              <e-col style="max-width: 100px">
                <erp-s-field label="ID">
                  <erp-input readonly v-model="documento.id"/>
                </erp-s-field>
              </e-col>
              <e-col>
                <erp-s-field label="Nome do documento *">
                  <erp-input autofocus v-model="documento.name"/>
                </erp-s-field>
              </e-col>
              <e-col style="max-width: 300px">
                <erp-s-field label="Tipo do documento">
                  <categoria-select ref="categoria" v-model="documento.categoria"/>
                </erp-s-field>
              </e-col>
            </e-row>
            <e-row mr>
              <e-col>
                <erp-s-field helper="Descreva para que fim será este documento" label="Descrição sobre o documento">
                  <erp-input class="m-t-xs" type="text" v-model="documento.description"/>
                </erp-s-field>
              </e-col>
              <e-col>
                <erp-s-field helper="Código" label="Código do documento">
                  <erp-input class="m-t-xs" type="text" v-model="documento.code"/>
                </erp-s-field>
              </e-col>
              <e-col>
                <erp-s-field label="Assunto"
                             helper="Para documentos de comunicação">
                  <erp-input class="m-t-xs" type="text" v-model="documento.subject"/>
                </erp-s-field>
              </e-col>
            </e-row>
            <e-row mr>
<!--              <e-col>
                <erp-s-field
                    helper="Se seu documento for um modelo em Word, ou arquivos estáticos como PDF, Excel, dentre outros, anexe-o abaixo"
                    label="Este documento é um arquivo?">
                  <input class="m-t-xs full-width" type="file"/>
                </erp-s-field>
              </e-col>-->
              <e-col class="m-l-sm" style="max-width: 100px">
                <erp-s-field label="Público">
                  <sl-checkbox v-model="documento.public" />
                </erp-s-field>
              </e-col>
              <e-col class="m-l-sm">
                <erp-s-field label="Receber Atualizações" icon-help="Se ativado, sempre que houver melhorias ou correções neste documento pelo desenvolvedor, o mesmo será atualizado.">
                  <sl-checkbox v-model="documento.receberAtualizacoes" />
                </erp-s-field>
              </e-col>
            </e-row>
          </div>
          <div class="wrapper-xs b preview-data" v-else-if="menuActive === 'preview'">
            <e-row class="m-b-n-sm" mr>
              <e-col style="max-width: 100px">
                <erp-s-field label="ID">
                  {{documento.id}}
                </erp-s-field>
              </e-col>
              <e-col>
                <erp-s-field label="CÓDIGO">
                  <div class="font-12"><span class="code">{{documento.code}}</span></div>
                </erp-s-field>
              </e-col>
              <e-col>
                <erp-s-field label="PÚBLICO">
                  <div class="font-12_">{{documento.public ? 'Sim' : 'Não'}}</div>
                </erp-s-field>
              </e-col>
              <e-col>
                <erp-s-field label="NOME">
                  {{documento.name}}
                </erp-s-field>
              </e-col>
              <e-col>
                <erp-s-field label="Assunto">
                  <div>{{documento.subject}}</div>
                </erp-s-field>
              </e-col>
            </e-row>
          </div>
        </div>
        <div v-if="menuActive === 'preview' || menuActive === 'edit'" class="doc-editor">
          <div class="editor-div">
            <div class="editor-preview">
              <div v-if="!loadingPreview" class="ep-container">
                <div class="text-center">Pré-visualização</div>
                <s-l-editor
                    ref="editor"
                    v-if="menuActive === 'preview' || !documento.id"
                    v-model="documento.documentPreview"
                    placeholder="Monte seu documento aqui..."
                    readonly
                    only-source
                    :show-toolbar="false"
                    :close-click-outside="false"
                />
              </div>
              <div v-else class="ep-container text-center flex flex-center justify-center center">
                <div class="font-12">CARREGANDO PRÉ-VISUALIZAÇÃO</div>
                <div>
                  <u-progress :percentage="loadingPreviewProgress" height="4px" style="width: 100px"/>
                </div>
              </div>
            </div>
            <div class="editor-source">
<!--              <div class="text-center">Código Fonte</div>-->
<!--              <textarea @change="preview" @keyup="previewTimeout" class="source-code" v-model="documento.document"></textarea>-->
              <code-editor v-model="documento.document" :languages="[['html']]" class="source-code" width="100%" height="100%" font_size="14px" :wrap_code="true" :spellcheck="false"></code-editor>
            </div>
          </div>
        </div>
        <doc-versoes :document="documento" v-if="menuActive === 'versions'" />
        <div class="doc-footer">
          <div></div>
          <div class="text-right">
            <!--            <u-btn label="Mostrar Variáveis" color="transparent" no-caps class="m-r-sm text-blue-grey-8 no-shadow" />-->
            <a @click.stop.prevent="openVars" class="m-r-lg font-normal no-select text-blue-grey-5">
              <u-icon name="brackets-curly" type="fa"/>
              {{ showVars ? 'Ocultar' : 'Mostrar' }} variáveis
            </a>
            <u-btn :disable="saving" @click="hide" label="Fechar" color="blue-grey-2" no-caps
                   class="m-r-sm text-blue-grey-8"/>
            <u-btn @click="save" :loading="saving" label="Salvar documento" color="positive" no-caps/>
          </div>
        </div>
      </div>
      <div v-else
           class="documento-container e-input-modern size1 flex full-height items-center justify-center column doc-loading">
        <div class="col-grow-1 full-height flex items-center justify-center column">
          <div>DOCS</div>
          <div>
            <u-progress :percentage="loadingProgress" height="4px" style="width: 100px"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ErpSField, ErpInput} from "uloc-vue-plugin-erp"
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import mockDocumento from "@/domain/documento/helpers/mockDocumento"
import SLEditor from "@/components/layout/components/Editor"
import Variaveis from "@/components/documentos/components/include/Variaveis"
import CategoriaSelect from "@/components/documentos/components/include/CategoriaSelect"
import {newDocumento, findDocumento, updateDocumento, previewDocumento} from "@/domain/documento/services"
import {debounce, UProgress} from "uloc-vue"
import DocVersoes from "@/components/documentos/components/include/Versoes"
import SlCheckbox from "@/reuse/input/Checkbox"
import CodeEditor from 'simple-code-editor'

export default {
  name: "Documento",
  data() {
    return {
      closed: false,
      showed: false,
      loading: false,
      saving: false,
      loadingProgress: 0,
      loadingPreview: false,
      loadingPreviewProgress: 0,
      showVars: false,
      documento: JSON.parse(JSON.stringify(mockDocumento)),
      textEditing: false,
      menuActive: 'preview',
      editorModel: null,
      menu: [
        {label: 'Resumo', id: 'preview'},
        {label: 'Editar', id: 'edit'},
        {label: 'Versões', id: 'versions'},
      ]
    }
  },
  watch: {
    'documento.document' (v) {
      this.$nextTick(() => {
        this.previewTimeout()
      })
    }
  },
  methods: {
    load(id) {
      this.loading = true
      this.loadingProgress = 0
      findDocumento(id)
          .then(response => {
            this.loadingProgress = 100
            this.documento = response.data
            setTimeout(() => {
              this.loading = false
            }, 200)
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    },
    show(id = null) {
      if (!id) {
        this.documento = JSON.parse(JSON.stringify(mockDocumento))
        this.menuActive = 'edit'
      } else {
        this.menuActive = 'preview'
        this.load(id)
        this.preview()
        /*this.$interval && clearInterval(this.$interval)
        this.$interval = setInterval(() => {
          this.preview()
        }, 5000)*/
      }
      this.showed = true
      this.closed = false
    },
    hide() {
      // this.$interval && clearInterval(this.$interval)
      setTimeout(() => {
        this.showed = false
        this.documento = JSON.parse(JSON.stringify(mockDocumento))
        this.textEditing = false
        this.menuActive = 'preview'
        this.editorModel = null
      }, 101)
      this.closed = true
    },
    toggle() {
      if (this.showed) {
        this.hide()
      } else {
        this.show()
      }
    },
    changeMenu(m) {
      /*if (this.menuActive === 'edit') {
        this.documento.document =  this.documento.document
      }*/
      if (m.id === 'preview' && this.documento.id) {
        this.preview()
      }
      this.$nextTick(() => {
        this.menuActive = m.id
      })
    },
    preview () {
      if (this.documento && this.documento.document) {
        this.loadingPreview = true
        this.loadingPreviewProgress = 0
        previewDocumento(this.documento.id || null, this.documento.document)
            .then(response => {
              this.loadingPreviewProgress = 100
              this.documento.documentPreview = response.data.documentPreview
              setTimeout(() => {
                this.loadingPreview = false
              }, 200)
            })
            .catch(error => {
              this.loadingPreview = false
              this.alertApiError(error)
            })
      }
    },
    previewTimeout: debounce(function () {
      this.preview()
    }, 2000),
    insertVar(v) {
      this.$refs.editor.addTextToCursor('{{' + v.name + '}}')
    },
    openVars() {
      this.$refs.vars.toggle()
      this.$nextTick(() => {
        setTimeout(() => {
          this.showVars = this.$refs.vars.showed
        }, 120)
      })
    },
    save() {
      let oldMenuActive = null
      if (this.menuActive !== 'edit') {
        oldMenuActive = this.menuActive
      }
      this.menuActive = 'edit'
      this.$nextTick(() => {
        const data = JSON.parse(JSON.stringify(this.documento))
        data.creteNewFolder = this.$refs.categoria.getNewData()
        data.document = this.documento.document
        console.log(data.document)
        const relacoes = ['categoria']
        relacoes.map(key => {
          if (data[key] && data[key].id) {
            data[key] = data[key].id
          }
        })
        this.saving = true
        let method = newDocumento
        if (data.id) {
          method = updateDocumento
        }
        method(data)
            .then(response => {
              this.saving = false
              if (oldMenuActive) {
                this.menuActive = oldMenuActive
              }
              this.dg()
              console.log(response)
            })
            .catch(error => {
              this.saving = false
              this.alertApiError(error)
            })
      })
    }
  },
  components: {
    SlCheckbox,
    DocVersoes,
    CategoriaSelect,
    Variaveis,
    SLEditor,
    ECol,
    ERow,
    ErpSField,
    ErpInput,
    UProgress,
    CodeEditor
  }
}
</script>
