<template>
  <u-icon v-if="type === 0" name="envelope" type="fa" icon-style="light" class="level1-icon fa-fw"/>
  <u-icon v-else-if="type === 1" name="file" type="fa" icon-style="light" class="level1-icon fa-fw"/>
</template>

<script>
export default {
  name: "TipoIcon",
  props: ['type']
}
</script>
