<template>
  <div class="doc-versoes">
    Não disponível no momento.
  </div>
</template>

<script>
export default {
  name: "DocVersoes",
  props: ['document']
}
</script>

