<template>
  <div class="doc-box box-left">
    <div class="header">
      <div class="lbl">
        Tipos de documentos
      </div>
      <div class="actions">
        <u-icon name="angle-double-left" type="fa" icon-style="regular"/>
      </div>
    </div>
    <div class="search bb dp">
      <u-input ref="searchInput" :autofocus="autofocus" v-model="search" class="doc-input" placeholder="Pesquisa rápida"
               hide-underline
               :before="[{icon: 'search', handler () {}}]"/>
    </div>
    <div v-if="!loading" class="menu-list">
      <ul class="level1">
        <li v-for="cat in filteredCategorias" :key="cat.id">
          <div @click="openCat(cat)" class="level-line">
            <u-icon name="caret-right" type="fa" class="fa-fw toggle-menu" :class="{open: cat.open}"/>
            <div :title="cat.code" class="label">
              <tipo-icon :type="cat.type"/>
              {{ cat.name }}
            </div>
            <a>
              <u-icon name="cog" type="fa" icon-style="regular"/>
            </a>
          </div>

          <ul class="level2" v-if="cat.open">
            <li v-for="cat1 in cat.childrens" :key="cat1.id" :class="{open: cat1.open}">
              <div @click="openFolder(cat1)" class="level-line">
                <u-icon name="caret-right" type="fa" class="fa-fw toggle-menu" :class="{open: cat1.open}"/>
                <div :title="cat1.code" class="label">
                  <i class="folder-icon" :class="{open: cat1.open}"/>
                  {{ cat1.name }}
                </div>
              </div>

              <ul class="level3" v-if="cat1.open">
                <li v-for="cat2 in cat1.childrens" :key="cat2.id" :class="{selected: active && active.id === cat2.id}">
                  <div @click="$emit('selected', cat2)" class="level-line">
                    <div :title="cat2.code" class="label">
                      <i class="data-icon"/>
                      {{ cat2.name }}
                    </div>
                  </div>
                </li>
                <li v-if="enableCreateType">
                  <div class="level-line full-width">
                    <u-input placeholder="Criar novo tipo" class="w-full" v-model="criar"/>
                  </div>
                </li>
                <!--                <li>
                                  <div class="level-line">
                                    <div class="label">
                                      <i class="data-icon"/>
                                      Comprovante de Lance
                                    </div>
                                  </div>
                                </li>-->
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div v-else class="menu-list">
      <div class="wrapper flex items-center justify-center col-grow-1">
        <sl-loading content=""/>
      </div>
    </div>
  </div>
</template>

<script>
import {UInput} from "uloc-vue"
import {listCategoria} from "@/domain/documento/services/categoria"
import SlLoading from "@/components/layout/components/Loading"
import TipoIcon from "@/components/documentos/components/include/TipoIcon"

export default {
  name: "TipoDocumentos",
  props: {
    active: null,
    autofocus: {
      type: Boolean,
      default: false
    },
    isPopup: {
      type: Boolean,
      default: false
    },
    enableCreateType: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      categorias: null,
      search: null,
      criar: null,
      folderSelected: null
    }
  },
  components: {TipoIcon, SlLoading, UInput},
  activated() {
    this.focus()
  },
  mounted() {
    this.isPopup && document.body.addEventListener('click', this.clickOutside, true)
    this.focus()
    this.load()
  },
  beforeDestroy() {
    this.isPopup && document.body.removeEventListener('click', this.clickOutside)
  },
  computed: {
    filteredCategorias() {
      if (this.search) {
        /*return this.categorias.filter(item => {
          return (Array.isArray(item.childrens) && item.childrens.length) || item.name.includes(this.search)
        })*/
        return this.maping(this.categorias)
      }
      return this.categorias
    }
  },
  watch: {
    criar (v) {
      if (v && v.length) {
        this.$nextTick(() => {
          this.$emit('selected', {
            name: this.criar,
            parent: this.folderSelected
          })
        })
      }
    },
    search (v) {
      this.$emit('search', v)
    }
  },
  methods: {
    focus() {
      if (this.autofocus) {
        setTimeout(() => {
          this.$refs.searchInput.focus()
        }, 100)
      }
    },
    load() {
      this.loading = true
      listCategoria()
          .then(response => {
            this.loading = false
            this.categorias = this.maping(response.data)
            console.log(this.categorias)
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    },
    maping(list, folder = false, type = '') {
      return list.filter(item => {
        if (!this.search) {
          return true
        }
        if (Array.isArray(item.childrens) && item.childrens.length) {
          return true
        }
        if (this.search.length < 3) {
          return item.name.toLowerCase().startsWith(this.search.toLowerCase())
        }
        return item.name.toLowerCase().includes(this.search.toLowerCase())
      }).map(cat => {
        if (!folder && type !== 'folder') {
          cat.open = false
        }
        if (type === 'folder' && folder) {
          cat.open = false
        }
        const result = {
          ...cat,
          open: !!this.search
        }
        if (result.childrens) {
          result.childrens = this.maping(result.childrens, true, type)
        }
        return result
      })
    },
    clickOutside(evt) {
      if (
          evt && evt.target &&
          (this.$el.contains(evt.target) || (this.$el.parentNode && this.$el.parentNode.contains(evt.target)))
      ) {
        return
      }
      this.$emit('close')
      // this.hide(evt)
    },
    openCat(cat) {
      this.folderSelected = null
      this.criar = null
      let o = !cat.open
      this.enableCreateType && this.maping(this.categorias)
      cat.open = o
    },
    openFolder(folder) {
      this.folderSelected = null
      this.criar = null
      let o = !folder.open
      this.enableCreateType && this.maping(this.categorias, false, 'folder')
      folder.open = o
      if (o) {
        this.folderSelected = folder
      }
    },
    getNewData() {
      if (!this.criar) {
        return null
      }
      let selected = this.folderSelected
      return {
        ...selected,
        isNew: true,
        newValue: this.criar
      }
    }
  }
}
</script>
