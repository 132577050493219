import http from "@/utils/services/http"

export const listCategoria = (limit, page, filtros) => {
    let url = '/api/documentos/categorias?page=' + page + '&limit=' + limit + filtros
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const findCategoria = (id) => {
    let url = `/api/documentos/categorias/${id}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const newCategoria = (data) => {
    let url = `/api/documentos/categorias`
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updateCategoria = (categoria, method = 'PUT') => {
    let url = `/api/documentos/categorias/${categoria.id}`
    let _method = http.put
    if (method === 'PATCH') {
        _method = http.patch
    }
    return _method(url, categoria)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deleteCategoria = (id) => {
    let url = `/api/documentos/categorias/${id}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
