export default {
    id: null,
    code: null,
    name: null,
    subject: null,
    description: null,
    document: '',
    documentPreview: '',
    internal: null,
    hits: null,
    categoria: null,
    public: false,
    receberAtualizacoes: true
}
