<template>
  <div v-if="showed" class="template-vars" :class="{closed: closed}">
    <div v-if="loading">Carregando variáveis...</div>
    <div v-else>
      <div class="m-t-n-sm m-b text-right">
        <u-btn icon="times" icon-type="fa" flat round rounded size="xs" class="" @click="showed = false"/>
      </div>
      <ul>
        <li class="font-bold title">Variáveis</li>
        <li class="font-bold m-b m-t-n-lg title">
          <erp-input v-model="filter" class="sl-input" autofocus placeholder="Pesquisar"/>
        </li>
        <li v-for="v in allVars">
          <a @click.stop.prevent="select(v)" class="no-select">
            <div class="font-12">{{ '{\{' + v.name + '}\}' }}</div>
            <div class="font-10 text-blue-grey-6">{{ v.description }}</div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {list} from '@/domain/variaveis/services'
import CopyToClipboard from "@/utils/copy-to-clipboard"
import ErpInput from "@/plugins/uloc-erp/components/form/input";

export default {
  name: 'Variaveis',
  components: {ErpInput},
  data() {
    return {
      closed: false,
      showed: false,
      loading: false,
      internals: null,
      variables: null,
      filter: null,
      fixeds: [
        {name: 'leilao.id', description: 'ID do leilão'},
        {name: 'leilao.titulo', description: 'Título do leilão'},
        {name: 'leilao.sites', description: ''},
        {name: 'leilao.aceitarCondicional', description: ''},
        {name: 'leilao.ano', description: ''},
        {name: 'leilao.auditorioPadrao', description: ''},
        {name: 'leilao.auditorioPublico', description: ''},
        {name: 'leilao.auditorioSempreAberto', description: ''},
        {name: 'leilao.bairro', description: ''},
        {name: 'leilao.cep', description: ''},
        {name: 'leilao.cidade', description: ''},
        {name: 'leilao.classificacao', description: ''},
        {name: 'leilao.cobraComissao', description: ''},
        {name: 'leilao.cobrarTaxasAdicionais', description: ''},
        {name: 'leilao.codigo', description: ''},
        {name: 'leilao.comitentes', description: ''},
        {name: 'leilao.config', description: ''},
        {name: 'leilao.controleAutomatico', description: ''},
        {name: 'leilao.controleSimultaneo', description: ''},
        {name: 'leilao.controleTempoInicial', description: ''},
        {name: 'leilao.cronometro', description: ''},
        {name: 'leilao.cronometroSempreAtivo', description: ''},
        {name: 'leilao.data1', description: ''},
        {name: 'leilao.data2', description: ''},
        {name: 'leilao.data3', description: ''},
        {name: 'leilao.dataAbertura', description: ''},
        {name: 'leilao.dataAbertura1', description: ''},
        {name: 'leilao.dataAbertura2', description: ''},
        {name: 'leilao.dataAbertura3', description: ''},
        {name: 'leilao.dataAberturaPraca2', description: ''},
        {name: 'leilao.dataFimPraca1', description: ''},
        {name: 'leilao.dataFimPraca2', description: ''},
        {name: 'leilao.dataProximoLeilao', description: ''},
        {name: 'leilao.dataPublicacao', description: ''},
        {name: 'leilao.descontoSegundaPraca', description: ''},
        {name: 'leilao.descontoTerceiraPraca', description: ''},
        {name: 'leilao.descricao', description: ''},
        {name: 'leilao.descricaoInterna', description: ''},
        {name: 'leilao.destaque', description: ''},
        {name: 'leilao.documentos', description: ''},
        {name: 'leilao.endereco', description: ''},
        {name: 'leilao.enderecoNumero', description: ''},
        {name: 'leilao.enderecoReferencia', description: ''},
        {name: 'leilao.extra', description: ''},
        {name: 'leilao.habilitacao', description: ''},
        {name: 'leilao.habilitacoes', description: ''},
        {name: 'leilao.habilitados', description: ''},
        {name: 'leilao.image', description: ''},
        {name: 'leilao.infoRetirada', description: ''},
        {name: 'leilao.infoVisitacao', description: ''},
        {name: 'leilao.inicioPregao', description: ''},
        {name: 'leilao.instancia', description: ''},
        {name: 'leilao.judicial', description: ''},
        {name: 'leilao.leiloeiro', description: ''},
        {name: 'leilao.loteado', description: ''},
        {name: 'leilao.lotes', description: ''},
        {name: 'leilao.multileiloeiro', description: ''},
        {name: 'leilao.multileiloeiroConfig', description: ''},
        {name: 'leilao.nomeclatura', description: ''},
        {name: 'leilao.numero', description: ''},
        {name: 'leilao.observacao', description: ''},
        {name: 'leilao.parcelamentoIndices', description: ''},
        {name: 'leilao.parcelamentoMinimoEntrada', description: ''},
        {name: 'leilao.parcelamentoQtdParcelas', description: ''},
        {name: 'leilao.patio', description: ''},
        {name: 'leilao.permitirEscolhaAuditorio', description: ''},
        {name: 'leilao.permitirLanceAntecipado', description: ''},
        {name: 'leilao.permitirLanceAutomatico', description: ''},
        {name: 'leilao.permitirParcelamento', description: ''},
        {name: 'leilao.permitirPropostas', description: ''},
        {name: 'leilao.praca', description: ''},
        {name: 'leilao.pregoes', description: ''},
        {name: 'leilao.publicarSite', description: ''},
        {name: 'leilao.publicarSiteQuando', description: ''},
        {name: 'leilao.regraIncremento', description: ''},
        {name: 'leilao.regras', description: ''},
        {name: 'leilao.servertime', description: ''},
        {name: 'leilao.sistemaTaxa', description: ''},
        {name: 'leilao.statsVisitas', description: ''},
        {name: 'leilao.status', description: ''},
        {name: 'leilao.statusInterno', description: ''},
        {name: 'leilao.textoHabilitacao', description: ''},
        {name: 'leilao.textoPropostas', description: ''},
        {name: 'leilao.timerIntervalo', description: ''},
        {name: 'leilao.timerPregao', description: ''},
        {name: 'leilao.timezone', description: ''},
        {name: 'leilao.tipo', description: ''},
        {name: 'leilao.tipoCronometro', description: ''},
        {name: 'leilao.totalLotes', description: ''},
        {name: 'leilao.uf', description: ''},
        {name: 'leilao.valorComissao', description: ''},
        {name: 'leilao.valorTaxaIcms', description: ''},
        {name: 'leilao.valorTaxaIss', description: ''},
        {name: 'leilao.vendaDireta', description: ''},
        {name: 'leilao.version', description: ''},
        {name: 'leilao.video', description: ''},
        {name: 'lote.id', description: ''},
        {name: 'lote.version', description: ''},
        {name: 'lote.cronometro', description: ''},
        {name: 'lote.dataFechamento', description: ''},
        {name: 'lote.dataFechado', description: ''},
        {name: 'lote.destaque', description: ''},
        {name: 'lote.historicoBem', description: ''},
        {name: 'lote.icms', description: ''},
        {name: 'lote.iss', description: ''},
        {name: 'lote.leiloes', description: ''},
        {name: 'lote.numero', description: ''},
        {name: 'lote.numeroString', description: ''},
        {name: 'lote.descricao', description: ''},
        {name: 'lote.observacao', description: ''},
        {name: 'lote.sobra', description: ''},
        {name: 'lote.status', description: ''},
        {name: 'lote.sucata', description: ''},
        {name: 'lote.valorArremate', description: ''},
        {name: 'lote.valorComissao', description: ''},
        {name: 'lote.valorImpostos', description: ''},
        {name: 'lote.baseImposto', description: ''},
        {name: 'lote.valorIncremento', description: ''},
        {name: 'lote.valorAvaliacao', description: ''},
        {name: 'lote.valorMercado', description: ''},
        {name: 'lote.valorInicial', description: ''},
        {name: 'lote.valorInicial2', description: ''},
        {name: 'lote.valorInicial3', description: ''},
        {name: 'lote.valorMeta', description: ''},
        {name: 'lote.valorMinimo', description: ''},
        {name: 'lote.valorTaxaArremate', description: ''},
        {name: 'lote.extra', description: ''},
        {name: 'lote.permitirParcelamento', description: ''},
        {name: 'lote.parcelamentoQtdParcelas', description: ''},
        {name: 'lote.parcelamentoMinimoEntrada', description: ''},
        {name: 'lote.parcelamentoIndices', description: ''},
        {name: 'lote.permitirPropostas', description: ''},
        {name: 'lote.videos', description: ''},
        {name: 'lote.textoTaxas', description: ''},
        {name: 'lote.totalLances', description: ''},
        {name: 'lote.statsVisitas', description: ''},
        {name: 'lote.entityOrigemCopia', description: ''},
        {name: 'lote.notaNumeroFixo', description: ''},
        {name: 'lote.arremate', description: ''},
        {name: 'lote.leilao', description: ''},
        {name: 'lote.bem', description: ''},
        {name: 'lote.historicoPregao', description: ''},
        {name: 'lote.lances', description: ''},
        {name: 'lote.lancesAutomaticos', description: ''},
        {name: 'lote.cancelamentoArremates', description: ''},
        {name: 'lote.cache', description: ''},
        {name: 'bem.id', description: ''},
        {name: 'bem.identificador', description: ''},
        {name: 'bem.origemCadastro', description: ''},
        {name: 'bem.destaque', description: ''},
        {name: 'bem.alagamento', description: ''},
        {name: 'bem.anoFabricacao', description: ''},
        {name: 'bem.anoModelo', description: ''},
        {name: 'bem.edital', description: ''},
        {name: 'bem.ata', description: ''},
        {name: 'bem.avarias', description: ''},
        {name: 'bem.bloqueadoLeilao', description: ''},
        {name: 'bem.catalogo', description: ''},
        {name: 'bem.chassi', description: ''},
        {name: 'bem.codigo', description: ''},
        {name: 'bem.codigoChave', description: ''},
        {name: 'bem.codigoFipe', description: ''},
        {name: 'bem.comissao', description: ''},
        {name: 'bem.dataAvaliacao', description: ''},
        {name: 'bem.dataEntrada', description: ''},
        {name: 'bem.dataHigienizacao', description: ''},
        {name: 'bem.dataSaida', description: ''},
        {name: 'bem.descricao', description: ''},
        {name: 'bem.descricaoLeiloeiro', description: ''},
        {name: 'bem.km', description: ''},
        {name: 'bem.liga', description: ''},
        {name: 'bem.localizacaoLatitude', description: ''},
        {name: 'bem.localizacaoLongitude', description: ''},
        {name: 'bem.localizacaoUrlGoogleMaps', description: ''},
        {name: 'bem.localizacaoUrlStreetView', description: ''},
        {name: 'bem.localizacaoMapEmbed', description: ''},
        {name: 'bem.local', description: ''},
        {name: 'bem.localChave', description: ''},
        {name: 'bem.motivoBloqueio', description: ''},
        {name: 'bem.extra', description: ''},
        {name: 'bem.nota', description: ''},
        {name: 'bem.observacaoConservacao', description: ''},
        {name: 'bem.observacaoLeiloeiro', description: ''},
        {name: 'bem.placa', description: ''},
        {name: 'bem.possuiChave', description: ''},
        {name: 'bem.situacaoChave', description: ''},
        {name: 'bem.renavam', description: ''},
        {name: 'bem.pais', description: ''},
        {name: 'bem.cep', description: ''},
        {name: 'bem.endereco', description: ''},
        {name: 'bem.numero', description: ''},
        {name: 'bem.endComplemento', description: ''},
        {name: 'bem.cidade', description: ''},
        {name: 'bem.bairro', description: ''},
        {name: 'bem.uf', description: ''},
        {name: 'bem.inscricaoMunicipal', description: ''},
        {name: 'bem.cartorio', description: ''},
        {name: 'bem.areaEdificada', description: ''},
        {name: 'bem.areaTerreno', description: ''},
        {name: 'bem.siteDescricao', description: ''},
        {name: 'bem.siteObservacao', description: ''},
        {name: 'bem.siteTitulo', description: ''},
        {name: 'bem.siteSubtitulo', description: ''},
        {name: 'bem.emEstoque', description: ''},
        {name: 'bem.status', description: ''},
        {name: 'bem.statusAnterior', description: ''},
        {name: 'bem.tipoInterno', description: ''},
        {name: 'bem.tituloLeiloeiro', description: ''},
        {name: 'bem.ufPlaca', description: ''},
        {name: 'bem.valorAvaliacao', description: ''},
        {name: 'bem.valorMercado', description: ''},
        {name: 'bem.valorInicial', description: ''},
        {name: 'bem.valorMeta', description: ''},
        {name: 'bem.valorMinimo', description: ''},
        {name: 'bem.image', description: ''},
        {name: 'bem.processoNumero', description: ''},
        {name: 'bem.processoExequente', description: ''},
        {name: 'bem.processoExecutado', description: ''},
        {name: 'bem.processoVara', description: ''},
        {name: 'bem.processoComarca', description: ''},
        {name: 'bem.prensa', description: ''},
        {name: 'bem.vendaDireta', description: ''},
        {name: 'bem.publicado', description: ''},
        {name: 'bem.tipoApreensao', description: ''},
        {name: 'bem.patio', description: ''},
        {name: 'bem.vaga', description: ''},
        {name: 'bem.arquivos', description: ''},
        {name: 'bem.modelo', description: ''},
        {name: 'bem.marca', description: ''},
        {name: 'bem.versao', description: ''},
        {name: 'bem.fipeAnoModelo', description: ''},
        {name: 'bem.pericias', description: ''},
        {name: 'bem.remocoes', description: ''},
        {name: 'bem.camposExtras', description: ''},
        {name: 'bem.acessorios', description: ''},
        {name: 'bem.tipo', description: ''},
        {name: 'bem.tipoPaiId', description: ''},
        {name: 'bem.tipoPai', description: ''},
        {name: 'bem.tipoCombustivel', description: ''},
        {name: 'bem.cor', description: ''},
        {name: 'bem.conservacao', description: ''},
        {name: 'bem.comitente', description: ''},
        {name: 'bem.lotes', description: ''},
        {name: 'bem.cache', description: ''},
        {name: 'bem.processo', description: ''},
        {name: 'bem.checklistJudicial', description: ''},
        {name: 'bem.tags', description: ''},
        {name: 'bem.propostas', description: ''},
        {name: 'bem.imovel', description: ''},
        {name: 'bem.veiculo', description: ''},
        {name: 'bem.visitas', description: ''},
        {name: 'bem.tour360', description: ''},
        {name: 'bem.caracteristicas', description: ''},
        {name: 'bem.campos', description: ''},
        {name: 'bem.observacoesInternas', description: ''},
        {name: 'bem.aprovadoVenda', description: ''},
        {name: 'bem.aprovadoVendaMotivo', description: ''},
        {name: 'bem.eventoInterno', description: ''},
        {name: 'bem.evento', description: ''},
        {name: 'bem.historicoProprietarios', description: ''},
        {name: 'bem.historicoProcessos', description: ''},
        {name: 'bem.videos', description: ''},
        {name: 'bem.checklists', description: ''},
        {name: 'comitente.id', description: ''},
        {name: 'comitente.apelido', description: ''},
        {name: 'comitente.image', description: ''},
        {name: 'comitente.observacao', description: ''},
        {name: 'comitente.newsletter', description: ''},
        {name: 'comitente.depositoDireto', description: ''},
        {name: 'comitente.config', description: ''},
        {name: 'comitente.prazoRemocao', description: ''},
        {name: 'comitente.prazoRemocaoHoras', description: ''},
        {name: 'comitente.tipo', description: ''},
        {name: 'comitente.usuarios', description: ''},
        {name: 'comitente.bens', description: ''},
        {name: 'comitente.pessoa', description: ''},
        {name: 'comitente.grupos', description: ''},
        {name: 'comitente.dadosBancarios', description: ''},
        {name: 'arrematante.id', description: ''},
        {name: 'arrematante.apelido', description: ''},
        {name: 'arrematante.image', description: ''},
        {name: 'arrematante.dataConferido', description: ''},
        {name: 'arrematante.observacao', description: ''},
        {name: 'arrematante.podeComprarSucata', description: ''},
        {name: 'arrematante.lanceAutomatico', description: ''},
        {name: 'arrematante.newsletter', description: ''},
        {name: 'arrematante.interesses', description: ''},
        {name: 'arrematante.estadoCivil', description: ''},
        {name: 'arrematante.conjugeCpf', description: ''},
        {name: 'arrematante.conjugeNome', description: ''},
        {name: 'arrematante.conjugeRg', description: ''},
        {name: 'arrematante.conjugeRegime', description: ''},
        {name: 'arrematante.pjProprietarioCpf', description: ''},
        {name: 'arrematante.pjProprietarioNome', description: ''},
        {name: 'arrematante.pjProprietarioRg', description: ''},
        {name: 'arrematante.pjProprietarioTelefoneComercial', description: ''},
        {name: 'arrematante.status', description: ''},
        {name: 'arrematante.termoRecebido', description: ''},
        {name: 'arrematante.pendencias', description: ''},
        {name: 'arrematante.saldo', description: ''},
        {name: 'arrematante.profissao', description: ''},
        {name: 'arrematante.regimeCasamento', description: ''},
        {name: 'arrematante.precadastro', description: ''},
        {name: 'arrematante.usuario', description: ''},
        {name: 'arrematante.documentos', description: ''},
        {name: 'arrematante.interesseArrematante', description: ''},
        {name: 'arrematante.pessoa', description: ''},
        {name: 'arrematante.lotesComprados', description: ''},
        {name: 'arrematante.lotesArrematados', description: ''},
        {name: 'arrematante.lances', description: ''},
        {name: 'arrematante.lancesAutomaticos', description: ''},
        {name: 'arrematante.analises', description: ''},
        {name: 'arrematante.leiloesHabilitacoes', description: ''},
        {name: 'arrematante.extras', description: ''},
        {name: 'arrematante.lotesFavoritos', description: ''},
        {name: 'arrematante.leiloesFavoritos', description: ''},
        {name: 'arrematante.bensFavoritos', description: ''},
        {name: 'arrematante.historicoInteresses', description: ''},
        {name: 'arrematante.interesseBens', description: ''},
      ]
    }
  },
  computed: {
    varInternals() {
      return this.variables.filter(v => v.internal)
    },
    variablesCustom() {
      return this.variables.filter(v => !v.internal)
    },
    allVars() {
      const all = [...this.varInternals, ...this.variablesCustom, ...this.fixeds]
      if (!this.filter) {
        return all
      }
      return all.filter(v => v.name.toLowerCase().includes(this.filter.toLowerCase()))
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    show() {
      this.showed = true
      this.closed = false
    },
    hide() {
      setTimeout(() => {
        this.showed = false
      }, 101)
      this.closed = true
    },
    toggle() {
      if (this.showed) {
        this.hide()
      } else {
        this.show()
      }
    },
    load() {
      this.loading = true
      list(1000, 1, '') // TODO Implementar acima de 1.000 variáveis
          .then(response => {
            console.log(response.data)
            this.internals = response.data.internals
            this.variables = response.data.result
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    },
    select(variable) {
      this.$emit('insertVar', variable)
      CopyToClipboard('{{' + variable.name + '}}')
          .then(() => {
            this.$uloc.notify({
              color: 'black',
              message: `Variável copiada!`,
              position: 'bottom-left',
            })
          })
          .catch(() => {
            this.$uloc.notify({
              color: 'negative',
              message: `Erro ao tentar copiar a variável!`,
              position: 'bottom-left',
            })
          })
    }
  }
}
</script>
