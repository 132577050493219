<template>
  <div class="doc-categoria-select" :class="{readonly: readonly}">
    <erp-input v-if="!readonly" @focus="focus" @blur="blur" @keydown="keyup" v-model="search" />
    <div v-if="active" class="selected">
      {{limit(active.parent.parent.name, 3)}}  <u-icon name="caret-right" type="fa" class="m-l-sm m-r-sm" /> <i class="folder-icon" /> {{limit(active.parent.name, 3)}} <i class="data-icon m-l-sm m-r-xs"/> {{limit(active.name, 20)}}
    </div>
    <tipo-documentos ref="popupCategorias" is-popup enable-create-type @close="closePopupCategorias" autofocus v-show="openPopupCategorias && !readonly" :active="active" @selected="selectCategory" />
  </div>
</template>

<script>
import {ErpInput} from "uloc-vue-plugin-erp"
import TipoDocumentos from "@/components/documentos/components/list/TipoDocumentos"
import {stopAndPrevent} from "uloc-vue/src/utils/event"

export default {
  name: "CategoriaSelect",
  props: {
    value: {
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      search: null,
      active: this.value,
      openPopupCategorias: false
    }
  },
  mounted() {
  },
  methods: {
    closePopupCategorias () {
      this.openPopupCategorias = false
    },
    focus () {
      console.log('Focus...')
      this.openPopupCategorias = true
      this.$nextTick(() => {
        this.$refs.popupCategorias.focus()
      })
    },
    blur () {
      console.log('Blur...')
      // this.openPopupCategorias = false
    },
    selectCategory (c) {
      console.log('Selected', c)
      this.$emit('input', c)
      this.active = c
    },
    keyup (e) {
      stopAndPrevent(e)
    },
    limit (s, l) {
      if (String(s).length <= l) {
        return s
      }
      return String(s).substring(0, l) + '...'
    },
    getNewData () {
      return this.$refs.popupCategorias.getNewData()
    }
  },
  components: {
    TipoDocumentos,
    ErpInput
  }
}
</script>
